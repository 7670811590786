<template>
  <div
    v-if="hasBreadcrumb && create"
    class="d-print-none"
    eagle-breadcrumb
  >
    <v-breadcrumbs :items='items'>
      <template v-slot:divider>
        <v-icon>fa fa-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script lang='babel' type='text/babel'>
export default {
  // 定義不同breadcrumb的位置
  props: {
    scope: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    items() {
      if(!this.hasBreadcrumb) return null

      let result = []
      for(const index in this.breadcrumb) {
        const item = this.breadcrumb[index]
        const isLast = index == this.breadcrumb.length-1
        let computedItem = {
          text: this.$t(item.label),
          to: item.route || null,
          exact: true,
          disabled: isLast
        }

        if(isLast == true) {
          delete computedItem.to
          computedItem.href = window.location.href
        }
        result.push(computedItem)
      }
      return result
    },
    hasBreadcrumb() {
      if(!Array.isArray(this.breadcrumb)) return false
      return this.breadcrumb.length > 0
    },
    breadcrumb() {
      return this.$store.getters['base/breadcrumb']
    },
    breadcrumbScope() {
      return this.$store.getters['base/breadcrumbScope']
    },
    create() {
      return this.scope === this.breadcrumbScope
    },
  },
  watch: {
    '$route.name': {
      deep: true,
      handler(after, before) {
        if(window.eagleLodash.isEqual(after, before) === true) return
        this.$store.dispatch('base/setBreadcrumb', [])
        this.$store.dispatch('base/setBreadcrumbScope', 'default')
      },
    },
    '$route.params': {
      deep: true,
      handler(after, before) {
        if(window.eagleLodash.isEqual(after, before) === true) return
        this.$store.dispatch('base/setBreadcrumb', [])
        this.$store.dispatch('base/setBreadcrumbScope', 'default')
      },
    },
  },
}
</script>

<style lang='sass' type='text/sass'></style>
